@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');

html {
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
}

body {
  margin: 0;
  font-family: 'NanumSquare',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2A3139;
}



code {
  font-family: 'NanumSquare',source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
    background-color: #2A3139;
    overflow-x: hidden;
  /*  @media only screen and (min-width: 850px) {
      width: ${props => (props.span ? (props.span / 12) * 100 : "8.33")}%;
    }
    */

}

input:-webkit-autofill {
   -webkit-box-shadow: 0 0 0 1000px white inset;
}


@media(min-width: 850px) {
  html{
    background-color: #000;
  }
  #root{
    /* DEMO width: 500px !important; */
    width: 500px;
    /* width: 100% !important; */
    /* DEMO added height 100% important */
    /* height: 100% !important; */
    position: absolute !important;
    left: 50% !important;
    transform: translate(-50%, 0) !important;
    background-color:#000;
  }
}


.modal-dialog{
   padding:12px;
   width: 335px;
   height: 280px;
   border-radius: 6px;
   background-color: #2A3139;
   margin: auto;
   top: 50%;
   position: absolute;
   left: 50%;
   transform: translate(-50%, -50%);
}

.modal-header{
  height: 30%;
  margin:auth;
  border-bottom:1px solid rgba(255, 255, 250, 0.2);
  color:#fff;
  text-align: center;
}
.modal-title{
  font-size: 0.75rem;
  line-height: 1.5;
}
.modal-body{
   color:#fff;
   padding-top: 10px;
   height: 120px;
}

.close{
  display: none;
}

.modal-footer{
  text-align: center;
}
.btn-secondary{
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color:#fff;
  width: 47.5%;
  height: 50px;
  font-size: 1.1rem;
  background-color: rgba(0, 0, 0, 0);
  margin-right: 3px;
  font-weight: 400;
}

.btn-primary{
  border-radius: 6px;
  color:#000;
  width: 47.5%;
  height: 50px;
  font-size: 1.1rem;
  background-color: #62e979;
  margin-left: 10px;
  font-weight: 400;
}
.popup-dialog .modal-content{
  height: 100%;
}

.popup-header{
  border-bottom:0px solid;
  height: 62%;
  vertical-align: middle;
  padding-top: 7%;
}
.popup-header .font-size{
  font-size: 1.2rem;
}

.popup-btn{
  background-color:#62e979;
  color:#000;
  width: 90%;
}

.apple-login-wrapper {
  width: 50px;
  height: 50px;
  display: inline-block;
  position: relative;
  margin:auto;
}
.apple-login-wrapper.hide { width: 1px; height: 1px;}
.apple-login-wrapper.hide #appleid-signin { background: none; }
#appleid-signin {
  position: absolute;
  width: 50px;
  height: 50px;
  background-image: url('/images/ic-apple.png');
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  background-color: rgba( 255,255,255,0.6 );
}
#appleid-signin > div {
  display: none;
  min-width: 50px !important;
  min-height: 50px !important;
  max-width: 100% !important;
  height: 100% !important;
}
#appleid-signin > img {
  width: 100%;
  height: 100%;
}

.chatroom{

}

.chat-message-form{
  display: flex;
  align-items: center;
  background-color: #5a6069;
}

.chat-message-form-wrapper{
  bottom:10px;
  margin:auto;
  margin-top: 10px;
}

.receipt-popup-header{
  border-bottom:0px solid;
  vertical-align: middle;
  padding-top: 7%;
}
.receipt-popup-header .font-size{
  font-size: 1.2rem;
}


.vjs-big-play-button{
  top: 40%;
  left: 40%;
  z-index:101;
  display: block;
  position: relative;
  display: none;

}
.vjs-has-started .vjs-big-play-button,
.video-js .vjs-big-play-button,
.video-js .vjs-picture-in-picture-control,
.vjs-playback-rate .vjs-playback-rate-value{
  display: none !important;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before{
  content: "" !important;
}
.video-js .vjs-big-play-button{
  top: 40%;
  left: 40%;
}


.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

div.transition-group {
  position: relative;
}

section.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

/*.rmp-quality, .rmp-360-nav, .rmp-i-live, .rmp-duration, .rmp-play-pause, .rmp-airplay, .rmp-audio, .rmp-captions {
  display: none !important;
}*/
/* .rmp-quality, .rmp-360-nav, .rmp-i-live, .rmp-duration, .rmp-airplay, .rmp-audio, .rmp-captions { */
.rmp-360-nav, .rmp-i-live, .rmp-duration, .rmp-airplay, .rmp-audio {
  display: none !important;
}
.rmp-cast{
  display: none!important;
}
.rmp-volume, .rmp-desktop-volume-icon {
  background-image: url('/images/ic-speaker-on@3x.png') !important;
  background-size: cover !important;
}
.rmp-desktop-volume-icon {
  left: 87% !important;

  width: 40px !important;
}
.rmp-volume.rmp-volume-off-mute {
  background-image: url('/images/ic-speaker-off@3x.png') !important;
  background-size: cover !important;
}
.rmp-i-resize-full, .rmp-fullscreen {
  background-image: url('/images/ic-maximize@3x.png') !important;
  background-size: cover !important;
  left: 100%!important;
  width: 40px !important;
}
.rmp-i-resize-small {
  background-image: url('/images/ic-minimize@3x.png') !important;
  background-size: cover !important;
  width: 40px !important;
}
.rmp-loading-spin {
  background-image: url('/images/rmpLoading.gif') !important;
  background-size: cover !important;
}
.sc-pRDlx{
  display: none!important;
  /* 360카메라 없앰 */
}

.eRAjIY{
  display: none!important;
}
.rmp-play-pause .rmp-button .rmp-i .rmp-i-pause,.rmp-i-pause, .rmp-pause {
  background-image: url('/images/btn-puase@3x.png') !important;
  background-size: cover !important;
  width: 40px !important;

}

.rmp-play-pause {
  left: 54%!important;
  margin:auto!important;
  display: block !important;
}


.rmp-pause-play .rmp-button .rmp-i .rmp-i-play, .rmp-i-play, .rmp-play {
  background-image: url('/images/btn-play@3x.png') !important;
  background-size: cover !important;
  width: 40px !important;
}
.rmp-i-play::before, .rmp-i-pause::before{
  content:"" !important;
}

/* @media only screen and (min-width: 850px) {
  .rmp-loading-spin {
    width: ;
  }
} */
.rmp-loading-spin-bounce {
  display: none !important;
}
.rmp-i-volume::before, .rmp-i-off-volume::before, .rmp-i-resize-full::before, .rmp-i-resize-small::before {
  content: none !important;
}


.rmp-container, .rmp-canvas-360 {
  width: 100% !important;
  height: 100% !important;
}
@media all and (orientation: landscape) {
  .rmp-container, .rmp-canvas-360 {
    height: 100% !important;
  }
}
@media only screen and (min-width: 850px) {
  .rmp-container, .rmp-canvas-360 {
    height: 100% !important;
  }
}

.rmp-extrasmall.rmp-mobile {
  display: block !important;
}

.rmp-control-bar{
  left: 0!important;
  right: 0!important;
  width: 70% !important;
  margin: auto !important;
  text-align: center !important;
  z-index: 160 !important;
}
.rmp-control-bar .rmp-time-elapsed { display: none;}



/* mobile portrait */
@media only screen and (max-width: 500px) {
  .rmp-module  {
    top: calc(100% - 40px) !important;
    z-index: 300 !important;
  }
  .rmp-captions {
    left: 35% !important;
  }

  .rmp-quality {
    bottom: 0!important;
    margin-top: auto!important;
    margin-right: auto!important;
    left: 0!important;
  }

  .rmp-volume {
    left: 76% !important;
  }
  .rmp-fullscreen {

    left: 100% !important;
  }

  .rmp-play-pause {
    left: 55% !important; 

  }
  .rmp-time-elapsed {
    display: none !important;
  }
  /* DEMO .rmp-i-resize-full, .rmp-fullscreen, .rmp-i-resize-small {
    display: none !important;
  } */
  /* .rmp-i-resize-full, .rmp-fullscreen, .rmp-i-resize-small {
    display: none !important;
  } */
  .rmp-volume, .rmp-volume.rmp-volume-off-mute {

    margin-left: 0px !important;
  }
  /* #rmp360Player .rmp-volume, .rmp-volume.rmp-volume-off-mute { */
  #rmp360Player .rmp-volume {
    margin-left: 10% !important;
  }
  #rmp360Player .rmp-volume-off-mute {
    margin-left: 10% !important;
  }
  #rmp360Player .rmp-fullscreen {
    display: block !important;
  }
}

/* mobile landscape */
@media all and (orientation: landscape) {
  .rmp-module {
    position: fixed !important;
    bottom: 0px !important;
    left: 78% !important;
    top: unset !important;
    z-index: 300 !important;
  }
  .rmp-control-bar{
    width: 60% !important;
    margin: auto !important;
    text-align: center !important;
    left: 30% !important;
  }
  /* DEMO .rmp-i-resize-full, .rmp-fullscreen, .rmp-i-resize-small {
    display: none !important;
  } */
  .rmp-volume, .rmp-volume.rmp-volume-off-mute {
    margin-left: 0px !important;
  }
  #rmp360Player .rmp-fullscreen {
    display: block !important;
  }
}

/* pc */
@media only screen and (min-width: 850px) {
  .rmp-desktop-volume-icon {
    left: 87% !important;
    right: 0!important;
    margin:auto!important;
  }
  .rmp-fullscreen {
    right: 0!important;
    /* margin:auto!important; */
  }
  .rmp-control-bar{
    width: 70% !important;
    margin: auto !important;
    text-align: center !important;
    left: 0 !important;
  }
  .rmp-i-resize-full, .rmp-fullscreen, .rmp-i-resize-small {
    display: block !important;
  }
  .rmp-i-resize-small {
    display: block !important;
  }
  .rmp-module  {
    top: calc(100% - 40px) !important;
    z-index: 300 !important;
  }
  .rmp-captions {
    left: 50% !important;
  }

  /* .rmp-module { */

}
.rmp-quality {
  height: 40px!important;
  bottom: 0!important;
  margin-top: auto!important;
  margin-right: auto!important;
  left: 55%!important;
}
.rmp-quality {
  /* vertical-align: top;
  display: inline!important; */
  /* left: 66% !important; */
  /* margin-left: auto!important;
  right: 5%!important;
  top: 0!important;
  margin-bottom: auto!important; */
}

.rmp-play-pause {

  right:  0%!important;
  top: 0!important;
  bottom: 0!important;
  align-items: center!important;
  vertical-align: middle!important;
  left: 0%!important;
  margin:auto!important;
  display: inline !important;
  height: 60px!important;
}
.rmp-control-bar{
  bottom: 0!important;
  width: 70% !important;
  height: 100%!important;
  margin: auto !important;
  text-align: center !important;
  left: 0 !important;
}
.rmp-fullscreen {
  color: #fff;
  top: 5%!important;
  left: 0!important;
  right: -10%!important;
  margin-left: auto!important;  
  margin-bottom: auto!important;
  height: 60px!important;
  /* margin:auto!important; */
}
.rmp-volume {
  bottom: 0!important;
  right: 0!important;
  margin-left: auto!important;  
  right: -20%!important;

  margin-top: auto!important;
  height: 40px !important;
}
.rmp-desktop-volume-icon {
  bottom: 0!important;
  margin-top: auto!important;
  height: 40px !important;
  right: -20%!important;

}


.rmp-module-overlay-icons {
  display: none !important;
}